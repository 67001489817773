import toast from "react-hot-toast";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { app_url } from "../config";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../components/Loader";
import { IoArrowBackCircle } from "react-icons/io5";
import NetworkErr from "../components/NetworkErr";

const PaymentReceiptSlug = () => {
  const token = JSON.parse(localStorage.getItem("etijwaal-agent")).token;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  // Extract the query parameter from the location search
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [networkErr, setNetworkErr] = useState(false);
  const [err400, setError400] = useState("");

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${app_url}/api/payment/getTapReceiptPartner?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          console.log(err.message);
          toast.error(err.message);
          setError400(err.message);
        } else {
          setNetworkErr(true);
          setData(err.message);
        }
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  }, []);

  if (networkErr) {
    return <NetworkErr data={data} />;
  }

  if (!id) {
    return <Navigate to={"/errPage"} state={{ message: "Page not found!" }} />;
  } else {
    return (
      <React.Fragment>
        <button
          className="flex items-center gap-2 mt-4"
          onClick={() => navigate("/accountBalance")}
        >
          <IoArrowBackCircle size={40} />
          <span>Go Back</span>
        </button>
        <section className="flex mx-auto justify-center items-center flex-col h-[85%]">
          {isLoading ? (
            <div className="h-[200px] w-[200px] flex items-center justify-center bg-gray-100">
              <Loader />
            </div>
          ) : (
            <div className="border-r-8 px-4 text-sm sm:text-base sm:px-16 border-t-8 border-orange-300 mt-12 sm:mt-8 shadow border bg-white rounded p-2 text-center relative pt-6">
              <img
                src="/assets/e-tijwaal.png"
                alt="logo"
                className="w-[100px]"
              />
              <h1 className="mb-0 text-2xl font-bold">Payment Receipt</h1>
              <h2 className="mb-4 font-semibold text-xl">
                {data[0]?.payment_id === null
                  ? "--"
                  : "#" + data[0]?.payment_id}
              </h2>

              <p className="mb-2 text-sm text-gray-400">
                For{" "}
                {data[0]?.company_name === null ? "--" : data[0]?.company_name}
              </p>
              <p className="text-sm text-gray-400">
                Paid on {new Date(data[0]?.created_at).toLocaleString()}
              </p>
              <div className="text-start py-2">
                <p>
                  Dear {data[0]?.agent_name === null ? "" : data[0]?.agent_name}
                  ,
                  <br />
                  <br />
                  Here's your payment receipt for charge{" "}
                  {data[0]?.payment_id === null
                    ? "--"
                    : "#" + data[0]?.payment_id}{" "}
                  for {data[0]?.paid_amount} {data[0]?.currency_used}
                  <br />
                  If you have any questions, please let us know.
                  <br />
                  <br />
                  Thanks, eTijwaal.
                </p>
                <div className="border-t border-b py-3 mt-8">
                  <p className="mb-2 flex justify-between">
                    <span>Payment ID</span>
                    <span className="font-bold">
                      {data[0]?.payment_id === null
                        ? "--"
                        : "#" + data[0]?.payment_id}
                    </span>
                  </p>
                  <p className="mb-2 flex justify-between">
                    <span>Payment Method:</span>
                    <span className="font-bold">{data[0]?.payment_method}</span>
                  </p>
                  <p className="flex mb-2 justify-between">
                    <span>Bonus: </span>
                    <span className="font-bold text-green-600">
                      {data[0]?.discount_amount + " " + data[0]?.currency_used}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span> Paid Amount: </span>
                    <span className="font-bold text-green-600">
                      {data[0]?.paid_amount + " " + data[0]?.currency_used}
                    </span>
                  </p>
                </div>
                <div className="border-b py-3 ">
                  <p className="flex justify-between">
                    <span>Total Amount</span>
                    <span className="font-bold">
                      {data[0]?.paid_amount + data[0]?.discount_amount} {" "} {data[0]?.currency_used}
                    </span>
                  </p>
                </div>
                <div className="footer text-center text-sm mt-6 text-gray-400">
                  Flat/Shop no 11 building 371 road 1912 block 319
                  MANAMA/ALHOORA
                </div>
              </div>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
};

export default PaymentReceiptSlug;
