import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { app_url } from "../../config";
import NetworkErr from "../../components/NetworkErr";
import toast from "react-hot-toast";
import Loader from "../../components/Loader";
import { FaArrowLeft } from "react-icons/fa";



const Paypal = () => {
  const [anomaly, setAnomaly] = useState("");
  const [online, setOnline] = useState(navigator.onLine);
  const [isLoading, setIsLoading] = useState(false);
  // const [orderId, setOrderId] = useState(null);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [pubKey, setPubKey] = useState("");
  const token = JSON.parse(localStorage.getItem("etijwaal-agent")).token;
  const { uuid, key } = useParams();
  const navigate = useNavigate();

  function onApprove(data) {
    return axios
      .post(
        `${app_url}/api/payment/capturePaypalOrder?sessionId=${uuid}&orderID=${data.orderID}`,
        {
          orderID: data.orderID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((orderData) => {
        if (orderData?.data?.payer?.name?.given_name) {
          toast.success(
            `Transaction completed by ${orderData.data.payer.name.given_name}`
          );
        } else {
          toast.success(`Transaction completed Successfully!!`);
        }
        navigate("/accountBalance");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message) {
          var a = error.response.data.message;
          if (typeof a === "string") {
            const data = JSON.parse(a);
            toast.error(data.details[0]?.description);
            console.log(
              "Payment Capture Error: ",
              data.details[0]?.description
            );
          }
        }
      });
  }

  async function createOrder() {
    const order = await axios.post(
      `${app_url}/api/payment/createPaypalOrder?sessionId=${uuid}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log('ORDER_ID', order.data.id);
    return order.data.id;
  }

  const handleError = (err) => {
    console.log("Payment error:", err.message);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${app_url}/api/payment/getPaypalSession?sessionId=${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { amount, currency, publishableKey } = res.data.data;
        setAmount(amount);
        setCurrency(currency);
        setPubKey(publishableKey);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.response?.data.message);
          navigate("/home");
          setIsLoading(false);
        } else if (err.response?.status === 500) {
          toast.error(err.response?.data.message);
          navigate("/home");
          setIsLoading(false);
        } else {
          setAnomaly(err.message);
          setOnline(navigator.onLine);
          setIsLoading(false);
        }
      });
  }, []);

  if (!online) {
    return <NetworkErr data={anomaly} />;
  }

  return (
    <>
      <div className="w-max flex gap-4 items-center mt-6 p-2 h-max">
        <Link to={"/home"} className="bg-red-500 p-2 rounded-md">
          <FaArrowLeft className="cursor-pointer" fill="#fff" />
        </Link>
        <h1 className="font-bold text-gray-500">Go Back</h1>
      </div>
      <div className="flex justify-center md:justify-between flex-col md:flex-row w-full mx-auto h-[80%] items-center">
        <div className="flex-1 hidden md:block">
          <img
            src="/assets/stripe.png"
            alt="stripe"
            className="w-[50%] mx-auto rounded"
          />
        </div>
        <div className="w-full pt-20 md:pt-0 md:flex-1">
          {isLoading ? (
            <div className="flex items-center justify-center h-[200px]">
              <Loader />
            </div>
          ) : (
            <>
              <div className="flex items-end gap-4 mb-4 shadow-xl rounded p-2 bg-gray-400 text-white w-max">
                <h1 className="text-2xl font-bold">Pay</h1>
                <p className="text-xl">
                  {/* <span className="me-2 text-xs text-white">US</span> */}
                  {currency === "USD"
                    ? `$${amount.toFixed(2)}`
                    : currency === "BHD"
                    ? `BD${amount.toFixed(2)}`
                    : ""}
                </p>
              </div>
              <PayPalScriptProvider options={{ "client-id": pubKey }}>
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={handleError}
                />
              </PayPalScriptProvider>
              <div className="flex flex-wrap sm:flex-nowrap w-full justify-center  gap-2 order-2 xl:order-1">
                <img
                  src="/assets/union-pay.png"
                  className="w-[35px] sm:w-[50px]"
                />
                <img src="/assets/visa.png" className="w-[35px] sm:w-[50px]" />
                <img
                  src="/assets/mastercard.png"
                  className="w-[35px] sm:w-[50px]"
                />
                <img
                  src="/assets/american-express.png"
                  className="w-[35px] sm:w-[50px]"
                />
                <img src="/assets/ucb.png" className="w-[35px] sm:w-[50px]" />
                <img
                  src="/assets/discover.png"
                  className="w-[35px] sm:w-[50px]"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Paypal;
