import React from 'react'

const CustomModal = () => {
  return (
    <React.Fragment>
      <div className="loading">
        <p className='text-white'>Please wait</p>
        <span><i></i><i></i></span>
      </div>
    </React.Fragment>
  )
}

export default CustomModal