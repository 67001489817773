import React from 'react'

const NetworkErr = ({data, minHeight, width}) => {
  return (
    <div className={`py-16 text-center ${ minHeight || 'min-h-[75vh]' } flex items-center ${width || ''} flex-col justify-center`}>
    <h1 className='text-red-500 font-semibold'>{data}</h1>
    <p className='text-sm mt-2'>Please try again later!</p>
  </div> 
  )
}

export default NetworkErr