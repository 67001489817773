import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const useResponsive = () => {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
    }, [width])

    return {width};
}

export default useResponsive