import React, { useEffect } from 'react'

const NotFoundPage = () => {
    
    
    
    useEffect(() => {

    }, [])


    return {
        
    }
}

export default NotFoundPage