// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


@media (min-width: 280px) and (max-width: 400px) {
    .change {
        width: 100% !important;
        height: 500px !important;
    }
}
@media (min-width: 400px) and (max-width: 500px) {
    .change {
        width: 380px !important;
        height: 380px !important;
    }
}
@media (min-width: 500px) and (max-width: 640px) {
    .change {
        width: 450px !important;
        height: 450px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/existingCustomers.css"],"names":[],"mappings":";;;AAGA;IACI;QACI,sBAAsB;QACtB,wBAAwB;IAC5B;AACJ;AACA;IACI;QACI,uBAAuB;QACvB,wBAAwB;IAC5B;AACJ;AACA;IACI;QACI,uBAAuB;QACvB,wBAAwB;IAC5B;AACJ","sourcesContent":["\n\n\n@media (min-width: 280px) and (max-width: 400px) {\n    .change {\n        width: 100% !important;\n        height: 500px !important;\n    }\n}\n@media (min-width: 400px) and (max-width: 500px) {\n    .change {\n        width: 380px !important;\n        height: 380px !important;\n    }\n}\n@media (min-width: 500px) and (max-width: 640px) {\n    .change {\n        width: 450px !important;\n        height: 450px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
