import { useEffect, useState } from "react"
import { createContext } from "react"


const initialState = {
    isLoggedIn: false,
    sideBarOpened: false,
    areYouSure: false,
    clickedOutside: false,
    customerModalOpen: false,
    profilePic: "",
    online: navigator.onLine,
    notificationCount: 10
}



const AuthContext = createContext()


const AuthProvider = ({ children }) => {
    const [state, setState] = useState(initialState)
    const user = localStorage.getItem('etijwaal-agent')

    const login = () => {
        setState((prevState) => ({
            ...prevState,
            isLoggedIn: true
        }));
    };
    const appLogout = () => {
        setState({ isLoggedIn: false })
        localStorage.removeItem('etijwaal-agent')
    }

    const toggleSideBar = () => {
        setState((prevState) => ({
            ...prevState,
            sideBarOpened: !prevState.sideBarOpened
        }));
    };
    const closeSideBar = () => {
        setState((prevState) => ({
            ...prevState,
            sideBarOpened: false
        }));
    };

    const handleClickOutside = () => {
        if (state.sideBarOpened === true)
            setState((prevState) => ({
                ...prevState,
                clickedOutside: true
            }))
    }

    const imageUpdated = (image) => {
        setState((prevState) => ({
            ...prevState,
            profilePic: image
        }))
    }

    const showModal = () => {
        setState((prevState) => ({
            ...prevState,
            areYouSure: true
        }))
    }
    const hideModal = () => {
        setState((prevState) => ({
            ...prevState,
            areYouSure: false
        }))
    }

    const setNotificationCount = (count) => {
        setState((prevState) => ({
            ...prevState,
            notificationCount: count
        }))
    }

    const openCustomerModal = () => {
        setState((prevState) => ({
            ...prevState,
            customerModalOpen: !prevState.customerModalOpen
        }))
    }
    const closeCustomerModal = () => {
        setState((prevState) => ({
            ...prevState,
            customerModalOpen: false
        }))
    }



    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            online: navigator.onLine
        }))
    }, [])

    useEffect(() => {
        if (user) {
            login()
        }
    }, [initialState.isLoggedIn])

    return (
        <AuthContext.Provider value={{
            ...state,
            login,
            appLogout,
            toggleSideBar,
            showModal,
            closeSideBar,
            hideModal, 
            handleClickOutside, 
            openCustomerModal, 
            closeCustomerModal, 
            imageUpdated,
            setNotificationCount
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider };
